@import "../../../../style/application/hornet_colors";
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

.form-check-input.is-invalid ~ .invalid-feedback:empty {
  display: none;
}

.invalid-feedback button {
  padding: 0;
  font-size: inherit;
}

.floating-form-group {
  position: relative;

  .password-eye {
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);
    cursor: pointer;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-background-clip: text;
    transition: background-color 5000s ease-in-out 0s;
  }

  .input-group {
    &.has-value {
      flex-wrap: unset;
    }

    padding: 10px 0;

    &.is-invalid {
      padding-bottom: 0;

      label {
        color: $hornet-red;
      }

      input {
        border-color: $hornet-red;
        box-shadow: unset;
      }
    }

    .cross-clear-hide {
      width: 25px;
      height: 25px;
      padding: 5px 5px;
      font-size: 16px;
    }

    .cross-clear {
      width: 25px;
      height: 25px;
      padding: 5px 5px;
      font-size: 16px;
      cursor: pointer;
    }

    &.with-pre-group {
      label,
      input:focus ~ label,
      label.has-value {
        left: 40px;
      }
    }
  }

  .was-validated .form-control:invalid, .form-control.is-invalid {
    background-image: unset;
  }

  label:not(.show-only) {
    position: absolute;
    pointer-events: none;
    left: 15px;
    transition: 0.2s ease all;
    top: 16px;
    background-color: white;
    width: 80%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  input,
  select,
  textarea,
  .base-select-box:not(:last-child) .base-select-box__control {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;

    &::placeholder {
      color: transparent;
    }
  }


  input, select, textarea {
    &:focus {
      z-index: unset !important;

      &::placeholder {
        color: $hornet-dark;
      }
    }
  }


  input, select, textarea {
    &:focus ~ label {
      color: $hornet-blue-border;
    }
  }

  input:focus ~ label,
  div:focus ~ label,
  select:focus ~ label,
  textarea:focus ~ label,
  label.has-value {
    top: 3px;
    font-size: 13px;
    opacity: 1;
    background-color: $hornet-neutral-0;
    left: 8px;
    padding: 0 5px;
    min-width: inherit;
    width: auto;
    overflow: unset;
    margin: 0;
    line-height: 1;
  }

  .document label.has-value {
    left: 20px;
  }

  .invalid-circle {
    width: 20px;
    height: 20px;
    text-align: center;
    position: absolute;
    right: 45px;
    top: 19px;

    svg {
      vertical-align: unset;
      margin-bottom: 3px;
    }
  }

  label.disabled {
    background-color: $gray-200;
  }

  @media (max-width: 480px) {
    label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 75%;
    }
  }

  label.signer-field-label {
    top: -1px;
  }

  .signer.input-group-append > .btn {
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
  }
}

.smart-address label {
  width: 75%;
}

.input-group.form-edit-floating {
  label {
    position: absolute;
    pointer-events: none;
    left: 13px;
    transition: 0.2s ease all;
    top: 7px;
    background-color: white;
    width: 80%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  label.has-value {
    font-size: 13px;
    opacity: 1;
    background-color: #FFF;
    left: 8px;
    padding: 0 5px;
    min-width: inherit;
    width: auto;
    overflow: unset;
    margin: 0;
    line-height: 1;

    &.disabled {
      &::after {
        content: ' ';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 50%;
        width: 100%;
        background: #e9ecef;
        z-index: 0;
      }

      span {
        z-index: 2;
        display: block;
        position: relative;
      }
    }
  }
}

.with-right-element {
  .floating-form-group {
    &.searchable-select {
      .invalid-circle {
        right: 120px;
        top: 28px;
      }
    }

    .with-pre-group.input-group {
      flex-wrap: unset;
    }

    .cross-clear {
      margin: 10px 0;
    }
  }
}
