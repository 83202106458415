@import "../../../style/application/hornet_colors";

.feedbackButton {
  position: fixed;
  bottom: 3%;
  cursor: pointer;
  z-index: 1100; // we need to show give feedback button over popup
  transition: all 300ms ease-in-out;
  background-color: $hornet-blue-50 !important;
  color: $hornet-blue-500 !important;
  &:hover {
    color: $hornet-blue-500 !important;
    text-decoration: none !important;
  }
}

.hornetNeutral500 {
  color: $hornet-neutral-500;
}

.submitFeedBackModal {
  .modal-dialog {
    max-width: 542px;
  }

  .header1 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
  }

  .header2 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }

  .hornetPrimaryButton {
    background-color: $hornet-blue-500;
    border-radius: 0;
    width: 100%;
    font-weight: 700;

    &:hover,
    &:active,
    &:focus {
      background-color: $hornet-blue-hover;
    }
  }

  .hornetPrimaryWhite {
    background-color: $hornet-neutral-0;
    border-radius: 0;
    width: 100%;
    color: $hornet-black;
    font-weight: 700;

    &:hover,
    &:active,
    &:focus {
      background-color: $hornet-neutral-0;
    }
  }

  textarea {
    resize: none;
  }

  .mobileBannerContainer {
    margin: 50px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    height: 243px;

    .img {
      width: 100%;
      scale: 1.4;
    }
  }
}
